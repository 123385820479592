import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EvaluatedSolutionsStore } from './evaluated-solutions.store';
import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-evaluated-solutions',
  templateUrl: './evaluated-solutions.component.html',
  styleUrls: ['./evaluated-solutions.component.scss'],
  providers: [EvaluatedSolutionsStore],
})
export class EvaluatedSolutionsComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;

  vm$ = this.evaluatedSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public evaluatedSolutionsStore: EvaluatedSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.evaluatedSolutionsStore.setUid(user?.uid);
        this.evaluatedSolutionsStore.setSolutionType(this.solutionType);
        this.evaluatedSolutionsStore.getBusinessUnitsOfEvaluator();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.evaluatedSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.evaluatedSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.evaluatedSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.evaluatedSolutionsStore.changeSearch(event);
  }

  onRefreshAfterEmit() {
    this.evaluatedSolutionsStore.refreshSolutions();
  }
}
