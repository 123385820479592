<veolia-modal #userSummaryModal>
  <div veolia-modal-title>
    <h4 nz-typography *ngIf="user?.type === 'Veolia'">
      {{ 'User overview for ' | translate }}
      <strong>{{ user?.displayName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ user?.uid! }}</em></span
      >
    </h4>
    <h4 nz-typography *ngIf="user?.type === 'Vendor'">
      {{ 'User overview for ' | translate }}
      <strong>{{ user?.lastname }} {{ user?.firstname }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ user?.uid! }}</em></span
      >
    </h4>
  </div>
  <div veolia-modal-content>
    <div *ngIf="user?.type === 'Veolia'">
      <div class="page-break" style="page-break-inside: avoid">
        <nz-card
          nzType="inner"
          nzTitle="{{ 'User solutions access' | translate }}"
          class="spacing-v16">
          <p class="row">
            <veolia-tag
              *ngFor="let solName of solutionsAccess"
              [label]="solName" />
          </p>
        </nz-card>

        <nz-card
          nzType="inner"
          nzTitle="{{ 'User informations' | translate }}"
          class="spacing-v16">
          <p>
            Full name: <strong>{{ user?.displayName }}</strong>
          </p>
          <p>
            Email: <strong>{{ user?.email }}</strong>
          </p>
          <p>
            Business unit: <strong>{{ user?.businessUnit }}</strong>
          </p>
          <p>
            Country (if defined):
            <strong>{{ user?.country || 'empty' }}</strong>
          </p>
          <p>
            Main role: <strong>{{ user?.role }}</strong>
          </p>
          <!-- <p>
            Additional rights: <strong>{{ user?.roles }}</strong>
          </p> -->
        </nz-card>
      </div>

      <div class="page-break" style="page-break-inside: avoid">
        <nz-card
          nzType="inner"
          nzTitle="{{ 'Additional informations' | translate }}"
          class="spacing-v16">
          <p *ngIf="user?.createdAt">
            First login:
            <strong>{{
              user?.createdAt!.toDate() | date: "MMM dd, yyyy 'at' hh:mm a"
            }}</strong>
          </p>
          <p *ngIf="!user?.createdAt">
            First login: <strong>undefined</strong>
          </p>
          <p *ngIf="user?.lastLoggedIn">
            Last login:
            <strong>{{
              user?.lastLoggedIn!.toDate() | date: "MMM dd, yyyy 'at' hh:mm a"
            }}</strong>
          </p>
          <p *ngIf="!user?.lastLoggedIn">
            Last login: <strong>undefined</strong>
          </p>
          <p>
            Terms accepted: <strong>{{ user?.termsAccepted }}</strong>
          </p>
          <p *ngIf="user?.termsAcceptedDate">
            Terms accepted on:
            <strong>{{
              (user?.termsAcceptedDate!.toDate()
                | date: "MMM dd, yyyy 'at' hh:mm a") || 'yes'
            }}</strong>
          </p>
          <p *ngIf="!user?.termsAcceptedDate">
            Terms accepted on: <strong>undefined</strong>
          </p>
        </nz-card>
      </div>
    </div>

    <div *ngIf="user?.type === 'Vendor'">
      <div class="page-break" style="page-break-inside: avoid">
        <nz-card
          nzType="inner"
          nzTitle="{{ 'User solutions access' | translate }}"
          class="spacing-v16">
          <p class="row">
            <veolia-tag
              *ngFor="let solName of solutionsAccess"
              [label]="solName" />
          </p>
        </nz-card>

        <nz-card
          nzType="inner"
          nzTitle="{{ 'User informations' | translate }}"
          class="spacing-v16">
          <p>
            Full name:
            <strong>{{ user?.firstname }} {{ user?.lastname }}</strong>
          </p>
          <p>
            Email: <strong>{{ user?.email }}</strong>
          </p>
          <p>
            Company: <strong>{{ user?.company }}</strong>
          </p>
          <p>
            Main role: <strong>{{ user?.role }}</strong>
          </p>
          <!-- <p>
            Additional rights: <strong>{{ user?.roles }}</strong>
          </p> -->
        </nz-card>
      </div>

      <div class="page-break" style="page-break-inside: avoid">
        <nz-card
          nzType="inner"
          nzTitle="{{ 'Additional informations' | translate }}"
          class="spacing-v16">
          <p *ngIf="user?.createdAt">
            Created on:
            <strong>{{
              (user?.createdAt!.toDate() | date: "MMM dd, yyyy 'at' hh:mm a") ||
                'yes'
            }}</strong>
          </p>
          <p *ngIf="!user?.createdAt">Created on: <strong>undefined</strong></p>
          <p>
            Created by: <strong>{{ user?.createdBy }}</strong>
          </p>
          <p *ngIf="user?.lastLoggedIn">
            Last login:
            <strong>{{
              (user?.lastLoggedIn!.toDate()
                | date: "MMM dd, yyyy 'at' hh:mm a") || 'yes'
            }}</strong>
          </p>
          <p *ngIf="!user?.lastLoggedIn">
            Last login: <strong>undefined</strong>
          </p>
          <p>
            Terms accepted: <strong>{{ user?.termsAccepted }}</strong>
          </p>
          <p *ngIf="user?.termsAcceptedDate">
            Terms accepted on:
            <strong>{{
              (user?.termsAcceptedDate!.toDate()
                | date: "MMM dd, yyyy 'at' hh:mm a") || 'yes'
            }}</strong>
          </p>
          <p *ngIf="!user?.termsAcceptedDate">
            Terms accepted on: <strong>undefined</strong>
          </p>
          <p>
            Password edited: <strong>{{ user?.passwordEdited }}</strong>
          </p>
          <p>
            Account disabled: <strong>{{ user?.disable }}</strong>
          </p>
        </nz-card>
      </div>
    </div>
  </div>
</veolia-modal>
