<div *ngIf="vm$ | async as vm">
  <app-simple-spinner-placeholder *ngIf="!vm.ready" />
  <app-form-toolbar
    *ngIf="vm.ready"
    [comments]="vm.comments"
    [fileList]="vm.fileList"
    [files]="vm.files"
    [evaluation]="vm.evaluation"
    [currentForm]="vm.form"
    [formName]="formName"
    [formCompletionStatus]="formCompletionStatus"
    [formStatus]="formStatus"
    [solution]="solution"
    [businessUnit]="businessUnit"
    (saveAll)="saveAll()"
    (markAsComplete)="markAsComplete(formCompletionStatus)" 
    (refreshForm)="refreshForm()" />
  <div nz-row [nzGutter]="16">
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row"
      [nzSpan]="5"
      [nzLg]="6"
      [nzMd]="6"
      [nzSm]="24"
      [nzXs]="24">
      <div class="rounded-white spacing-b16">
        <h4 nz-typography>Table of content</h4>
        <div>
          <app-form-block-button
            *ngIf="
              this.genaiQuestionDesign === 'Yes' ||
              this.solution?.genaiQuestion === 'Yes'
            "
            [showId]="false"
            id="1"
            [title]="'AR28_GENAI' | translate"
            (click)="selectChapter('AR28_GENAI')"
            evaluationField="AR28_GENAI_RADIO"
            [selected]="selectedChapter === 'AR28_GENAI'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="2"
            [title]="'AR28_TOMS' | translate"
            (click)="selectChapter('AR28_TOMS')"
            evaluationField="AR28_TOMS_RADIO"
            [selected]="selectedChapter === 'AR28_TOMS'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="3"
            [title]="'AR28_DATA' | translate"
            (click)="selectChapter('AR28_DATA')"
            evaluationField="AR28_DATA_RADIO"
            [selected]="selectedChapter === 'AR28_DATA'"
            [unlocked]="true">
          </app-form-block-button>
        </div>
      </div>
    </div>
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row chapters"
      [nzLg]="18"
      [nzMd]="18"
      [nzSm]="24"
      [nzXs]="24">
      <app-form-chapter
        *ngIf="
          fields['AR28_GENAI']?.fields &&
          (this.genaiQuestionDesign === 'Yes' ||
            this.solution?.genaiQuestion === 'Yes')
        "
        [evaluation]="fields['AR28_GENAI']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR28_GENAI'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 28 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR28_GENAI'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR28_GENAI')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['AR28_TOMS']?.fields"
        [evaluation]="fields['AR28_TOMS']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR28_TOMS'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 28 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR28_TOMS'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR28_TOMS')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['AR28_DATA']?.fields"
        [evaluation]="fields['AR28_DATA']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR28_DATA'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 28 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR28_DATA'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR28_DATA')"
        (formEdited)="formEdited($event)"></app-form-chapter>
    </div>
  </div>
</div>
