import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PresenceService } from './core/services/presence.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { AuthService } from './core/services/auth.service';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { Subscription } from 'rxjs';
import { TabService } from './core/services/tab.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'frontend-v2';
  countdown?: number;
  lastPing?: Date;
  idleState = 'NOT_STARTED';
  private subscriptions: Subscription[] = [];
  showConfirm = false;
  private tabId: string;

  @ViewChild('modal') modal!: VeoliaModalComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private presenceService: PresenceService,
    private translate: TranslateService,
    private tabService: TabService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.tabId = Math.random().toString(36).substr(2, 9);

    // Presence
    // set idle parameters
    this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(3600); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
      this.presenceService.updateOnAway();
      this.presenceService.setPresence(this.auth.currentUser?.uid!, 'away');
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.presenceService.setPresence(this.auth.currentUser?.uid!, 'online');
      this.countdown = undefined;
      this.cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.presenceService.updateOnDisconnect();
      this.authService.SignOut();
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => (this.countdown = seconds));

    // set keepalive parameters, omit if not using keepalive
    this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date())); // do something when it pings
  }

  ngOnInit() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.tabService.initializeTabManager(user.uid, this.tabId);
        this.subscriptions.push(
          this.tabService.getShowConfirm().subscribe(show => {
            if (show) {
              this.modal.open();
            }
            this.showConfirm = show;
          })
        );
        this.subscriptions.push(
          this.tabService.checkIfShouldRedirect().subscribe(shouldRedirect => {
            if (shouldRedirect) {
              window.location.href = 'https://www.veolia.com';
            }
          })
        );

        this.subscriptions.push(
          this.tabService.getShouldRedirect().subscribe(shouldRedirect => {
            if (shouldRedirect) {
              window.location.href = 'https://www.veolia.com';
            }
          })
        );
      }
    });

    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }

  setActiveTab() {
    this.tabService.setActiveTab();
    this.modal.close();
  }

  private onBeforeUnload() {
    this.tabService.closeTab();
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = undefined;
    this.lastPing = undefined;
  }
}
