<router-outlet></router-outlet>
<veolia-modal #modal>
  <div veolia-modal-title>DLA</div>
  <div veolia-modal-content>
    DLA is opened in another window(s) or tab(s). Please verify if you don't
    have unsaved changes.<br />
    Click "Use Here" to use DLA in this window.
  </div>
  <div veolia-modal-footer>
    <veolia-button (click)="setActiveTab()">Use Here</veolia-button>
  </div>
</veolia-modal>
