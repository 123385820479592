import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';

import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent implements AfterContentChecked {
  user?: User;

  @ViewChild('userSummaryModal') modal!: VeoliaModalComponent;

  solutionsAccess?: Array<string> = [];

  constructor(
    private cDRef: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngAfterContentChecked(): void {
    this.cDRef.detectChanges();
  }

  // ngAfterViewInit(): void {
  //   if (this.user?.uid) {
  //     this.userService.get(this.user?.uid).then(data => {
  //       this.user = { uid: data.id, ...data.data() } as User;
  //     });
  //   }
  // }

  open(user: User) {
    this.user = user;

    this.solutionsAccess = [];

    if (this.user.type === 'Veolia') {
      this.userService
        .getSolutionsAccessForVeoliaUser(this.user?.email)
        .forEach(res => {
          res.forEach(element => {
            this.solutionsAccess?.push(
              element.data()['solutionName'] + ' (' + element.id + ')'
            );
          });
        })
        .catch(error => {
          this.solutionsAccess = undefined;
          console.log(error);
        });
    }
    if (this.user.type === 'Vendor') {
      this.userService
        .getSolutionsAccessForVendorUser(this.user?.email)
        .forEach(res => {
          res.forEach(element => {
            this.solutionsAccess?.push(
              element.data()['solutionName'] + ' (' + element.id + ')'
            );
          });
        })
        .catch(error => {
          this.solutionsAccess = undefined;
          console.log(error);
        });
    }

    this.modal.open();
  }
}
