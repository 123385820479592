<veolia-header id="navbar">
  <veolia-header-logo class="pointer" (click)="goToHomeUser()">
    <strong>DLAaaS</strong>
    <veolia-divider orientation="vertical" variant="light" /> Design & Legal
    Assessment as a Service
  </veolia-header-logo>
  <app-logo />
  <veolia-divider orientation="vertical" variant="light" />
  <veolia-toolbar>
    <veolia-toolbar-item
      icon="home"
      label="Home"
      (selectedChange)="goToHomeUser()" />
    <veolia-toolbar-item
      *ngIf="user?.roles?.admin! === true"
      icon="settings"
      label="Administration"
      (selectedChange)="goToAdmin()" />
    <veolia-toolbar-item
      *ngIf="user?.roles?.evaluator! === true"
      icon="gavel"
      label="Evaluation"
      (selectedChange)="goToEvaluatorBu()" />
    <veolia-toolbar-item
      *ngIf="user?.termsAccepted"
      nz-tooltip
      nzTooltipTitle="Help"
      icon="help"
      label="Help"
      (selectedChange)="goToHelp()" />
  </veolia-toolbar>

  <veolia-divider orientation="vertical" variant="light" />
  <a nz-dropdown [nzDropdownMenu]="menu">
    <veolia-profile
      *ngIf="user?.type! === 'Veolia'"
      [displayName]="initialsVeolia"
      [showDot]="false"
      [imageUrl]="user?.photoURL!"
      align="right" />
    <veolia-profile
      *ngIf="user?.type! === 'Vendor'"
      [displayName]="initialsVendor"
      [showDot]="false"
      align="right" />
  </a>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item><strong>UID: </strong> {{ user?.uid }}</li>
      <li nz-menu-item><strong>Email: </strong> {{ user?.email }}</li>
      <li nz-menu-item *ngIf="user?.isVeolia">
        <strong>BU: </strong> {{ user?.businessUnit }}
      </li>
      <li nz-menu-item><strong>Role: </strong> {{ user?.role }}</li>
      <li nz-menu-item>
        <veolia-link
          *ngIf="user?.termsAccepted && user?.type! === 'Vendor'"
          [size]="'small'"
          [icon]="'lock_open'"
          (click)="goToEditPassword()">
          {{ 'Edit password' | translate }}
        </veolia-link>
      </li>
      <li nz-menu-item>
        <veolia-link [size]="'small'" [icon]="'logout'" (click)="logout()">
          {{ 'Logout' | translate }}
        </veolia-link>
      </li>
    </ul>
  </nz-dropdown-menu>
</veolia-header>
<app-contact-form #contactForm />
