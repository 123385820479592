<ng-container *ngIf="vm$ | async as vm">
  <nz-alert
    *ngIf="vm.error"
    nzType="error"
    [nzMessage]="vm.error"
    nzShowIcon></nz-alert>
  <div>
    <div class="row row-space-between row-right">
      <search-input
        [isSearching]="vm.isSearching"
        (onChange)="onSearch($event)"></search-input>
    </div>
    <simple-spinner
      style="text-align: center !important"
      *ngIf="vm.isLoading"></simple-spinner>
    <app-solutions-table
      [actions]="['share', 'summary', 'access', 'logs']"
      [solutions]="vm.solutions"
      [solutionType]="vm.solutionType"></app-solutions-table>
  </div>
  <app-pagination
    [loading]="vm.isLoading || vm.isSearching"
    [size]="vm.limit"
    [page]="vm.currentPage"
    [total]="vm.total"
    (onPageChange)="changePage($event)"
    (onSizeChange)="changeSize($event)"></app-pagination>
</ng-container>
