export enum FormStatus {
  CANCELLED = 'Canceled',
  COMPLETED = 'Completed',
  DLA_IS_REVIEWING = 'DLA is reviewing',
  VENDOR_IS_RESPONDING = 'Vendor is responding',
  ONGOING = 'Ongoing',
  EMPTY = 'Empty',
  NEW = 'New',
  EMPTY_EVAL = 'DLA Assessement',
  NOT_APPLICABLE = 'Not applicable',
  NOT_RECOMMENDED = 'Not recommended',
  ON_HOLD = 'On hold',
  PENDING = 'Pending',
  RECOMMENDED = 'Recommended',
  RESERVATIONS = 'Reservations',
  RECOMMENDED_RESERVATIONS = 'Recommended with reservations',
}
