import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Timestamp, serverTimestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';
import { NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { getTagColor } from 'src/app/core/utils/colors';
import { formatDate } from 'src/app/core/utils/date';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';

@Component({
  selector: 'app-solutions-table',
  templateUrl: './solutions-table.component.html',
  styleUrls: ['./solutions-table.component.scss'],
})
export class SolutionsTableComponent extends DataTableComponent {
  @Input() solutions: Solution[] = [];
  @Input() solutionType = 0;
  @Input() showId = true;
  @Input() actions: string[] = [];

  @Output() sorted: EventEmitter<{
    key: string;
    value: NzTableSortOrder;
  }> = new EventEmitter();
  @Output() refresh: EventEmitter<void> = new EventEmitter();

  #service = inject(VeoliaModalService);

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public override solutionService: SolutionService,
    public sscApi: SecurityScoreCardApi,
    public sscService: SecurityScoreCardService,
    public messageService: VeoliaMessageService
  ) {
    super(authService, router, translateService, solutionService);
  }

  getTagColor(status: string) {
    return getTagColor(status);
  }

  getFormattedDate(date: string | Timestamp) {
    return formatDate(date);
  }

  hasAction(action: string) {
    return this.actions.includes(action);
  }

  //data-table functions overrided
  override launchCancelRequestAction(solution: Solution) {
    this.solutionService.cancelSolution(solution.uid, solution).then(() => {
      this.refresh?.emit();
      window.alert('Solution ' + solution.solutionName + ' has been canceled');
    });
  }

  override launchUncancelRequestAction(solution: Solution) {
    this.solutionService.unCancelSolution(solution.uid, solution).then(() => {
      this.refresh?.emit();
      window.alert('Solution ' + solution.solutionName + ' has been restored');
    });
  }

  queryParamsChange(params: NzTableQueryParams) {
    if (params.sort[0]) {
      this.sorted.emit({
        key: params.sort[0].key,
        value: params.sort[0].value,
      });
    }
  }

  refreshSsc(solution: Solution) {
    this.sscApi.addPortofolio(solution?.uid, solution?.link).subscribe({
      next: () => {
        this.sscApi.getScore(solution?.uid, solution?.link).subscribe({
          next: data => {
            this.sscService
              .get(solution?.uid)
              .then(doc => {
                if (doc.exists()) {
                  this.sscService
                    .update(solution?.uid, {
                      ...data,
                      lastUpdateDate: Timestamp.now(),
                    })
                    .then(() => {
                      this.messageService.create(
                        {
                          title: 'Success',
                          content: 'Security score card refreshed !',
                          icon: 'check',
                        },
                        {
                          duration: 3000,
                        }
                      );
                      this.sscApi
                        .removePortofolio(solution?.uid, solution?.link)
                        .subscribe();
                      this.refresh?.emit();
                    })
                    .catch(err => console.log(err));
                } else {
                  this.sscService
                    .create(
                      {
                        ...data,
                        solutionName: solution?.solutionName,
                        idSolution: solution?.uid,
                        firstAddDate: Timestamp.now(),
                      },
                      solution?.uid
                    )
                    .then(() => {
                      this.messageService.create(
                        {
                          title: 'Success',
                          content: 'Security score card created !',
                          icon: 'check',
                        },
                        {
                          duration: 3000,
                        }
                      );
                      this.sscApi
                        .removePortofolio(solution?.uid, solution?.link)
                        .subscribe();
                      this.refresh?.emit();
                    })
                    .catch(err => console.log(err));
                }
              })
              .catch(error => {
                console.log(error);
              });
          },
          error: () => {
            this.messageService.create(
              {
                title: 'Error',
                content: 'Oops! Can not refresh security score card',
                icon: 'error',
              },
              {
                duration: 3000,
              }
            );
          },
        });
      },
      error: () => {
        this.messageService.create(
          {
            title: 'Error',
            content: 'Oops! Can not add to portofolio security score card',
            icon: 'error',
          },
          {
            duration: 3000,
          }
        );
      },
    });
  }
}
