import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
  VeoliaAvatarComponent,
  VeoliaAvatarGroupComponent,
} from '@veolia.com/vds-angular-components/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommonModule } from '@angular/common';
import { Auth } from '@angular/fire/auth';
import { map } from 'rxjs';
import { DocumentPresenceService } from './document-presence.service';

export interface PresentUser {
  userId: string;
  userFullName: string;
  status: string;
  timestamp: number;
  color: string;
  initials: string;
}

@Component({
  selector: 'app-document-presence',
  templateUrl: './document-presence.component.html',
  styleUrls: ['./document-presence.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    NzToolTipModule,
    NzAvatarModule,
    VeoliaAvatarGroupComponent,
    VeoliaAvatarComponent,
  ],
})
export class DocumentPresenceComponent implements OnInit, OnDestroy {
  @Input() documentId?: string;
  @Input() form?: string;
  @Input() userId?: string;

  presentUsers: PresentUser[] = [];
  twoFirstPresent: PresentUser[] = [];
  remainingPresent: PresentUser[] = [];

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;

  constructor(
    private auth: Auth,
    private presence: DocumentPresenceService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {}

  ngOnInit() {
    this.userId = this.auth.currentUser?.uid;
    if (!this.documentId || !this.form || !this.userId) {
      console.error(
        'DocumentComponent: documentId, form, and userId are required inputs'
      );
      return;
    }

    this.presence
      .getUsers(this.documentId, this.form)
      .pipe(
        map(users => {
          return users.filter(user => user.status !== 'offline');
        }),
        map(users =>
          users.map(user => ({
            ...user,
            initials: this.getInitials(user.displayName),
          }))
        )
      )
      .subscribe(users => {
        this.presentUsers = users.filter(
          el => el.email !== this.auth.currentUser?.email
        );
        this.updatePresentUserLists();
      });

    this.presence.updatePresence(
      this.documentId,
      this.userId,
      this.auth.currentUser?.email!,
      this.auth.currentUser?.displayName!,
      this.form,
      'online'
    );

    // Idle configuration
    this.configureIdle();
  }

  ngOnDestroy() {
    this.idle.stop();
    if (this.documentId && this.userId && this.form) {
      this.presence.removePresence(this.documentId, this.userId, this.form);
    }
  }

  private configureIdle() {
    this.idle.setIdle(900); // au bout de 15 minutes le user est considéré absent
    this.idle.setTimeout(1800); // au bout de 30 minutes d'inactivité le user est automatiquement deconnecté
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.presence.updatePresence(
        this.documentId!,
        this.userId!,
        this.auth.currentUser?.email!,
        this.auth.currentUser?.displayName!,
        this.form!,
        'online'
      );
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.presence.updatePresence(
        this.documentId!,
        this.userId!,
        this.auth.currentUser?.email!,
        this.auth.currentUser?.displayName!,
        this.form!,
        'offline'
      );
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.presence.updatePresence(
        this.documentId!,
        this.userId!,
        this.auth.currentUser?.email!,
        this.auth.currentUser?.displayName!,
        this.form!,
        'idle'
      );
    });

    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.idle.watch();
  }

  private updatePresentUserLists() {
    this.twoFirstPresent = this.presentUsers.slice(0, 2);
    this.remainingPresent = this.presentUsers.slice(2);
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  }
}
