import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentPresenceService {
  constructor(private afs: AngularFirestore) {}

  updatePresence(
    documentId: string,
    userId: string,
    email: string,
    displayName: string,
    form: string,
    status: 'online' | 'idle' | 'offline'
  ) {
    const presenceRef = this.afs.doc(
      `documents/${documentId}/forms/${form}/presence/${userId}`
    );

    const data = {
      userId,
      displayName,
      email,
      status: status,
      lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
    };

    return presenceRef.set(data, { merge: true });
  }

  getUsers(documentId: string, form: string): Observable<any[]> {
    return this.afs
      .collection(`documents/${documentId}/forms/${form}/presence`)
      .valueChanges();
  }

  removePresence(documentId: string, userId: string, form: string) {
    return this.afs
      .doc(`documents/${documentId}/forms/${form}/presence/${userId}`)
      .delete();
  }
}
