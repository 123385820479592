import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';

import { Solution } from 'src/app/core/models/solution';
import { User } from 'src/app/core/models/user';

import { AccessRightsComponent } from '../../../access-rights/components/access-rights/access-rights.component';
import { UserSummaryComponent } from '../../../user-summary/components/user-summary.component';
import { UserManagementComponent } from '../../../user-management/components/user-management.component';
import { ValidateSolutionComponent } from 'src/app/features/admin/components/solutions/validate-solution/validate-solution.component';
import { SolutionInfoSummaryComponent } from '../../../solution-info-summary/components/solution-info-summary.component';
import { SolutionShareComponent } from '../../../solution-share/components/solution-share.component';
import { ContactFormComponent } from '../../../contact-form/contact-form.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
  @ViewChild('solutionInfoSummaryModal')
  solutionInfoSummary!: SolutionInfoSummaryComponent;

  @ViewChild('validateSolutionModal')
  validateSolution!: ValidateSolutionComponent;

  @ViewChild('userSummaryModal')
  userSummary!: UserSummaryComponent;

  @ViewChild('userManagementModal')
  userManagement!: UserManagementComponent;

  @ViewChild('solutionShareModal')
  solutionShare!: SolutionShareComponent;

  @ViewChild('accessRightsModal')
  accessRights!: AccessRightsComponent;

  @ViewChild('contactFormModal')
  contactFormModal!: ContactFormComponent;

  constructor(
    public authService: AuthService,
    public router: Router,
    public translateService: TranslateService,
    public solutionService: SolutionService
  ) {}

  launchAction(action: string, solution: Solution) {
    //home + admin + evaluator tables + summary/id + logs/id + form/id pages
    if (action === 'info') {
      this.solutionInfoSummary.open(solution);
    } else if (action === 'share') {
      this.solutionShare.open(solution, this.authService.type!);
    } else if (action === 'access') {
      this.accessRights.open(solution, this.authService.type!);
    } else if (action === 'help') {
      this.contactFormModal.open(solution);
    } else if (action === 'logs') {
      //page
      this.router.navigate([`/logs/${solution.uid}`]);
    } else if (action === 'summary') {
      //page
      this.router.navigate([`/summary/${solution.uid}`]);
    } else if (action === 'edit') {
      //page
      this.router.navigate([`/demands/form/${solution.uid}/edit`]);
    }
  }

  //home + admin + evaluator tables + summary/id + logs/id pages
  launchForm(solution: Solution, formName: string) {
    this.router.navigate([`/${formName}/${solution.uid}`]);
  }

  //ADMIN
  //users-table
  launchSummaryUserModal(user: User) {
    this.userSummary.open(user);
  }

  //ADMIN
  //users-table
  launchEditRolesUserModal(user: User) {
    this.userManagement.open(user);
  }

  //ADMIN
  //solutions-table
  launchValidateRequestModal(solution: Solution) {
    this.validateSolution.open(solution);
  }

  //ADMIN
  //users-table
  launchResetUserPassword(user: User) {
    this.authService.forgotPassword(user?.email);
  }

  //ADMIN
  //overrided in admin solutions-table
  launchCancelRequestAction(solution: Solution) {
    this.solutionService.cancelSolution(solution.uid, solution);
  }

  //ADMIN
  //overrided in admin solutions-table
  launchUncancelRequestAction(solution: Solution) {
    this.solutionService.unCancelSolution(solution.uid, solution);
  }

  //ADMIN
  //overrided in admin users-table
  launchDisableUserAccount(user: User) {
    this.authService.disableVendorUser(user?.uid, user?.email);
  }

  //ADMIN
  //overrided in admin users-table
  launchEnableUserAccount(user: User) {
    this.authService.enableVendorUser(user?.uid, user?.email);
  }
}
