import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';
import { Observable } from 'rxjs';
import { ChapterStatus } from 'src/app/core/models/chapter-status';
import { Evaluation } from 'src/app/core/models/evalutation';
import { FileItem } from 'src/app/core/models/file-item';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { CommentService } from 'src/app/core/services/comment.service';
import { FileService } from 'src/app/core/services/file.service';
import { LogService } from 'src/app/core/services/log.service';
import { getTagColor } from 'src/app/core/utils/colors';
import { Article28FormStore } from 'src/app/features/demands/components/article-28-form/article-28-form.store';
import { Article32FormStore } from 'src/app/features/demands/components/article-32-form/article-32-form.store';
import { DesignFormStore } from 'src/app/features/demands/components/design-form/design-form.store';
import { LegalFormStore } from 'src/app/features/demands/components/legal-form/legal-form.store';
import { FormActionStatusComponent } from '../form-action-status/form-action-status.component';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent implements OnInit {
  @Input() isChapter = false;
  @Input() fieldId = '';
  @Input() fieldTitle?: string;
  @Input() formName = '';
  @Input() solutionId = '';
  @Input() solutionName = '';
  @Input() completable = false;
  @Input() evaluation?: any;
  @Input() formEvaluation?: any;
  @Input() assessable = true;

  vm$?: Observable<any>;

  commentsCount = 0;
  comments: Comment[] = [];
  completionStatus = false;
  filesCount = 0;
  files: FileItem[] = [];
  // evaluation?: Evaluation;
  evaluationStatus?: string = 'DLA Assessment';
  evaluationColor?:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue';
  evaluationCommentField?: string;
  evaluationStatusField?: string;
  statusColor?:
    | 'default'
    | 'error'
    | 'warning'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue'
    | undefined;
  statusKey?: string;
  statusLabel = 'DLA Assessment';
  statusComment = '';
  changing = false;

  @ViewChild('formActionCommentsTpl', { static: true })
  formActionCommentsTpl!: TemplateRef<any>;

  @ViewChild('formActionFilesTpl', { static: true })
  formActionFilesTpl!: TemplateRef<any>;

  @ViewChild('formActionStatus', { static: true })
  formActionStatus!: FormActionStatusComponent;

  public _auth: Auth = inject(Auth);

  constructor(
    private article28FormStore: Article28FormStore,
    private article32FormStore: Article32FormStore,
    private auth: Auth,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private chapterCompletionStatusService: ChapterCompletionStatusService,
    private commentService: CommentService,
    private designFormStore: DesignFormStore,
    private fileService: FileService,
    private legalFormStore: LegalFormStore,
    private logService: LogService,
    private veoliaModalService: VeoliaModalService
  ) {}

  ngOnInit(): void {
    if (this.evaluation) {
      this.initEvaluationData();
    }
    this.fetchCommentsCount();
    this.fetchFilesCount();

    if (this.formName === 'Article 28 Form') {
      this.vm$ = this.article28FormStore.vm$;
      this.article28FormStore
        .select(state => state.evaluation)
        .subscribe(evaluation => {
          this.updateEvaluationStatus(evaluation);
        });
      this.article28FormStore
        .select(state => state.statuses)
        .subscribe(statuses => this.updateCompletionStatus(statuses));
    }

    if (this.formName === 'Article 32 Form') {
      this.vm$ = this.article32FormStore.vm$;
      this.article32FormStore
        .select(state => state.evaluation)
        .subscribe(evaluation => {
          this.updateEvaluationStatus(evaluation);
        });

      this.article32FormStore
        .select(state => state.statuses)
        .subscribe(statuses => this.updateCompletionStatus(statuses));
    }

    if (this.formName === 'Design Form') {
      this.vm$ = this.designFormStore.vm$;
      this.designFormStore
        .select(state => state.evaluation)
        .subscribe(evaluation => {
          this.updateEvaluationStatus(evaluation);
        });
      this.designFormStore
        .select(state => state.statuses)
        .subscribe(statuses => this.updateCompletionStatus(statuses));
    }

    if (this.formName === 'Legal Form') {
      this.vm$ = this.legalFormStore.vm$;
      this.legalFormStore
        .select(state => state.evaluation)
        .subscribe(evaluation => {
          this.updateEvaluationStatus(evaluation);
        });
      this.legalFormStore
        .select(state => state.statuses)
        .subscribe(statuses => this.updateCompletionStatus(statuses));
    }
  }

  updateEvaluationStatus(evaluation: Evaluation | undefined) {
    if (evaluation) {
      this.evaluationStatus =
        evaluation[this.evaluationStatusField!] ?? 'DLA Assessment';
      this.evaluationColor = getTagColor(this.evaluationStatus);
    }
  }

  updateCompletionStatus(statuses: Record<string, ChapterStatus> | undefined) {
    if (statuses && Object.keys(statuses).length !== 0) {
      const status = statuses[this.fieldId];
      if (status) {
        if (status.status === 'Completed') {
          this.completionStatus = true;
        }
      }
    }
  }

  initEvaluationData() {
    if (this.evaluation) {
      const { commentField, statusField } = this.evaluation;

      if (statusField) {
        this.evaluationStatusField = statusField;
      }

      if (commentField) {
        this.evaluationCommentField = commentField;
      }
    }
  }

  get isVendor() {
    if (this.formName === 'Article 28 Form') {
      return this.article28FormStore
        .getSolution()
        ?.emailVendorAuthorized?.includes(this.auth.currentUser?.email!);
    }

    if (this.formName === 'Article 32 Form') {
      return this.article32FormStore
        .getSolution()
        ?.emailVendorAuthorized?.includes(this.auth.currentUser?.email!);
    }

    if (this.formName === 'Design Form') {
      return this.designFormStore
        .getSolution()
        ?.emailVendorAuthorized?.includes(this.auth.currentUser?.email!);
    }

    if (this.formName === 'Legal Form') {
      return this.legalFormStore
        .getSolution()
        ?.emailVendorAuthorized?.includes(this.auth.currentUser?.email!);
    }

    return false;
  }

  openComments(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.veoliaModalService.open({
      title: 'Comment for: ' + this.fieldTitle,
      content: this.formActionCommentsTpl,
      data: {
        comments: this.comments,
        fieldId: this.fieldId,
        formName: this.formName,
        solutionId: this.solutionId,
      },
      closable: true,
    });
  }

  openEvaluations(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.formActionStatus.open(
      {
        fieldId: this.fieldId,
        formName: this.formName,
        solutionId: this.solutionId,
        commentField: this.evaluation?.commentField || '',
        solutionName: this.solutionName || '',
        statusField: this.evaluation?.statusField || '',
        status: this.statusLabel,
        statusKey: this.statusKey!,
        comment: this.statusComment,
        isVendor: this.isVendor!,
      },
      this.isVendor!
    );
  }

  openFiles(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.veoliaModalService.open({
      title: 'Files for: ' + this.fieldTitle,
      content: this.formActionFilesTpl,
      data: {
        files: this.files,
        fieldId: this.fieldId,
        formName: this.formName,
        solutionId: this.solutionId,
      },
      closable: true,
    });
  }

  fetchCommentsCount() {
    if (this.formName && this.solutionId && this.fieldId) {
      this.commentService
        .getCommentsCount(this.formName, this.solutionId, this.fieldId)
        .then(data => {
          this.commentsCount = data as number;
          this.cd.detectChanges();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  fetchFilesCount() {
    if (this.formName && this.solutionId && this.fieldId) {
      this.fileService
        .getChapterFilesCount(this.formName, this.fieldId, this.solutionId)
        .then(data => {
          this.filesCount = data as number;
          this.cd.detectChanges();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  switchClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  updateChapterStatus(status: boolean) {
    this.changing = true;
    const path = `/completeChapSubChap/${this.formName}/${this.solutionId}/${this.fieldId}`;

    const chapterStatus: ChapterStatus = this.getChapterStatus();

    const payload = {
      addedAt: new Date(Date.now()).toString(),
      addedBy: this._auth.currentUser?.email,
      formIs: this.formName,
      idRef: `${this.solutionId}-${this.fieldId}`,
      idSolution: this.solutionId,
      reference: this.fieldId,
      solutionName: this.solutionName,
      status: status ? 'Completed' : 'Not completed',
      userId: this._auth.currentUser?.uid,
    };

    if (chapterStatus) {
      this.chapterCompletionStatusService
        .updateCompletionStatus(`${path}/${chapterStatus.id!}`, payload)
        .then(() => {
          this.changing = false;
          this.logService.setLogForAction(
            'Complete',
            this.solutionId,
            this.formName,
            {
              action: 'Chapter/sub-chapter completed',
              chapter: this.fieldId,
              comment: null,
              roleUser: this.authService.userRole,
              status: status ? 'Completed' : 'Not completed',
            }
          );
          this.reloadStatuses();
        })
        .catch(error => {
          console.log('Errrr', error);
          this.changing = false;
        });
    } else {
      this.chapterCompletionStatusService
        .setCompletionStatus(path, payload)
        .then(() => {
          this.logService.setLogForAction(
            'Complete',
            this.solutionId,
            this.formName,
            {
              action: 'Chapter/sub-chapter completed',
              chapter: this.fieldId,
              comment: null,
              roleUser: this.authService.userRole,
              status: status ? 'Completed' : 'Not completed',
            }
          );
          this.changing = false;
          this.reloadStatuses();
        })
        .catch(error => {
          console.log('Errrr', error);
          this.changing = false;
        });
    }
  }

  getChapterStatus() {
    switch (this.formName) {
      case 'Article 28 Form':
        return this.article28FormStore.getStatus(this.fieldId);
      case 'Article 32 Form':
        return this.article32FormStore.getStatus(this.fieldId);
      case 'Legal Form':
        return this.legalFormStore.getStatus(this.fieldId);
      case 'Design Form':
        return this.designFormStore.getStatus(this.fieldId);
      default:
        return this.designFormStore.getStatus(this.fieldId);
    }
  }

  reloadStatuses() {
    if (this.formName === 'Article 28 Form') {
      this.article28FormStore.getStatuses();
    }

    if (this.formName === 'Article 32 Form') {
      this.article32FormStore.getStatuses();
    }

    if (this.formName === 'Design Form') {
      this.designFormStore.getStatuses();
    }

    if (this.formName === 'Legal Form') {
      this.legalFormStore.getStatuses();
    }
  }

  refreshCommentsCount() {
    this.fetchCommentsCount();

    if (this.formName === 'Article 28 Form') {
      this.article28FormStore.getAllComments();
    }

    if (this.formName === 'Article 32 Form') {
      this.article32FormStore.getAllComments();
    }

    if (this.formName === 'Design Form') {
      this.designFormStore.getAllComments();
    }

    if (this.formName === 'Legal Form') {
      this.legalFormStore.getAllComments();
    }
  }

  refreshFilesCount() {
    this.fetchFilesCount();

    if (this.formName === 'Article 28 Form') {
      this.article28FormStore.getAllFiles();
    }

    if (this.formName === 'Article 32 Form') {
      this.article32FormStore.getAllFiles();
    }

    if (this.formName === 'Design Form') {
      this.designFormStore.getAllFiles();
    }

    if (this.formName === 'Legal Form') {
      this.legalFormStore.getAllFiles();
    }
  }

  getSolution() {
    switch (this.formName) {
      case 'Article 28 Form':
        return this.article28FormStore.getSolution();
      case 'Article 32 Form':
        return this.article32FormStore.getSolution();
      case 'Design Form':
        return this.designFormStore.getSolution();
      case 'Legal Form':
        return this.legalFormStore.getSolution();
      default:
        return this.designFormStore.getSolution();
    }
  }

  getBusinessUnit() {
    switch (this.formName) {
      case 'Article 28 Form':
        return this.article28FormStore.getBusinessUnit();
      case 'Article 32 Form':
        return this.article32FormStore.getBusinessUnit();
      case 'Design Form':
        return this.designFormStore.getBusinessUnit();
      case 'Legal Form':
        return this.legalFormStore.getBusinessUnit();
      default:
        return this.designFormStore.getBusinessUnit();
    }
  }

  isVendorReadOnly(formName: string) {
    switch (this.formName) {
      case 'Article 28 Form':
        return this.getSolution()?.emailVendorReadonlyArt28?.includes(
          this.auth.currentUser?.email!
        );
      case 'Article 32 Form':
        return this.getSolution()?.emailVendorReadonlyArt32?.includes(
          this.auth.currentUser?.email!
        );
      case 'Design Form':
        return this.getSolution()?.emailVendorReadonlyDesign?.includes(
          this.auth.currentUser?.email!
        );
      case 'Legal Form':
        return this.getSolution()?.emailVendorReadonlyLegal?.includes(
          this.auth.currentUser?.email!
        );
      default:
        return false;
    }
  }

  isAssessed(formName: string) {
    switch (formName) {
      case 'Article 28 Form':
        return (
          this.getSolution()?.article28Status === 'Recommended' ||
          this.getSolution()?.article28Status === 'Reservations' ||
          this.getSolution()?.article28Status === 'Not recommended'
        );
      case 'Article 32 Form':
        return (
          this.getSolution()?.article32Status === 'Recommended' ||
          this.getSolution()?.article32Status === 'Reservations' ||
          this.getSolution()?.article32Status === 'Not recommended'
        );
      case 'Design Form':
        return (
          this.getSolution()?.designStatus === 'Recommended' ||
          this.getSolution()?.designStatus === 'Reservations' ||
          this.getSolution()?.designStatus === 'Not recommended'
        );
      case 'Legal Form':
        return (
          this.getSolution()?.legalStatus === 'Recommended' ||
          this.getSolution()?.legalStatus === 'Reservations' ||
          this.getSolution()?.legalStatus === 'Not recommended'
        );
      default:
        return false;
    }
  }

  get isViewer() {
    return this.authService.roles?.viewer;
  }

  get canComplete() {
    return (
      (this.isAdmin || (this.isVendor && !this.isVendorReadOnly(this.formName!))) && !this.isAssessed(this.formName!)
    );
  }

  get userIsSoOfSolution() {
    return this.getSolution()?.solutionOwner === this.auth.currentUser!.email!;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.getBusinessUnit()?.evaluators.includes(
        this.auth.currentUser!.email!
      ) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.getSolution()?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get isAdmin() {
    return this.authService.roles?.admin === true;
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }
}
