import { Component, OnInit, ViewChild, signal } from '@angular/core';
import { User } from '../../../../../core/models/user';
import { AuthService } from '../../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { ContactFormComponent } from '../../../contact-form/contact-form.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('contactForm') contactForm!: ContactFormComponent;

  protected user?: User;
  protected userProfile? = signal<unknown | undefined>(undefined);
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.user = user;
        this.setProfile(user);
      }
    });
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  get initialsVeolia(): string {
    return this.user ? this.user?.displayName : '';
  }

  get initialsVendor(): string {
    return this.user ? this.user?.firstname + ' ' + this.user?.lastname : '';
  }

  setProfile(user: User) {
    if (user.firstname !== undefined && user.lastname !== undefined) {
      this.userProfile?.set({
        firstName: user.firstname,
        lastName: user.lastname,
        imageUrl: user.photoURL,
        email: user.email,
        uid: user.uid,
        flag: user.isVeolia ? 'veolia' : '',
        roles: [
          {
            label: 'UID',
            role: user.uid,
          },
          {
            label: 'BU',
            role: user.businessUnit,
          },
          {
            label: 'Role',
            role: user.role,
          },
        ],
      });
    } else {
      const nameParts = this.user?.displayName.split(' ');
      const firstName =
        nameParts?.slice(0, nameParts.length - 1).join(' ') ?? '';
      const lastName = nameParts?.slice(-1).join(' ') ?? '';
      this.userProfile?.set({
        firstName: firstName,
        lastName: lastName,
        imageUrl: this.user?.photoURL,
        email: this.user?.email,
        uid: this.user?.uid,
        flag: this.user?.isVeolia ? 'veolia' : '',
        roles: [
          {
            label: 'UID',
            role: user.uid,
          },
          {
            label: 'BU',
            role: user.businessUnit,
          },
          {
            label: 'Role',
            role: user.role,
          },
        ],
      });
    }
  }

  goToAdmin() {
    this.router.navigate(['/admin']);
  }

  goToEvaluatorBu() {
    this.router.navigate(['/evaluator']);
  }

  goToHomeUser() {
    this.router.navigate(['/']);
  }

  goToEditPassword() {
    this.router.navigate(['/edit-password']);
  }

  goToHelp() {
    if (this.authService.roles?.vendor) {
      this.contactForm.open();
    } else {
      this.contactForm.open();
      // window.open('https://veglobaltest.service-now.com/dlaaas', '_blank');
    }
  }

  public logout(): void {
    this.authService.SignOut();
  }
}
