import { NgModule } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AuthService } from '../../../core/services/auth.service';
import { UserService } from '../../../core/services/user.service';
import {
  VeoliaHeaderComponent,
  VeoliaHeaderLogoComponent,
} from '@veolia.com/vds-angular-components/header';
import { VeoliaProfileComponent } from '@veolia.com/vds-angular-components/profile';
import { VeoliaAvatarComponent } from '@veolia.com/vds-angular-components/avatar';
import {
  VeoliaToolbarComponent,
  VeoliaToolbarItemComponent,
} from '@veolia.com/vds-angular-components/toolbar';
import {
  VeoliaBentoComponent,
  VeoliaBentoItemsComponent,
  VeoliaBentoItemComponent,
} from '@veolia.com/vds-angular-components/bento';
import { VeoliaDividerComponent } from '@veolia.com/vds-angular-components/divider';
import { VeoliaDropdownComponent } from '@veolia.com/vds-angular-components/dropdown';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzMenuDirective, NzMenuModule } from 'ng-zorro-antd/menu';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { LogoComponent } from '../logo/logo.component';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ContactApi } from 'src/app/core/apis/contact.api';
import { SolutionService } from 'src/app/core/services/solution.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    LogoComponent,
    NzButtonModule,
    NzDividerModule,
    NzDropDownModule,
    NzMenuModule,
    ReactiveFormsModule,
    FormlyModule,
    NzFormModule,
    NzToolTipModule,
    VeoliaIconComponent,
    VeoliaHeaderComponent,
    VeoliaProfileComponent,
    VeoliaToolbarComponent,
    VeoliaToolbarItemComponent,
    VeoliaHeaderLogoComponent,
    VeoliaBentoComponent,
    VeoliaBentoItemsComponent,
    VeoliaBentoItemComponent,
    VeoliaButtonComponent,
    VeoliaDividerComponent,
    VeoliaDropdownComponent,
    VeoliaLinkComponent,
    VeoliaAvatarComponent,
    VeoliaModalModule,
    TranslateModule,
    ContactFormComponent,
    RouterModule,
  ],
  exports: [NavbarComponent],
  providers: [
    AuthService,
    ContactApi,
    SolutionService,
    UserService,
    NzMenuDirective,
  ],
})
export class NavbarModule {}
