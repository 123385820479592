import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  getDatabase,
  ref,
  onValue,
  set,
  remove,
  push,
  Database,
} from 'firebase/database';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private tabId?: string;
  private userId: string | null = null;
  private showConfirmSubject = new BehaviorSubject<boolean>(false);
  private shouldRedirectSubject = new BehaviorSubject<boolean>(false);
  private db: Database;
  private tabCount = 0;

  constructor(private zone: NgZone) {
    this.db = getDatabase();
  }

  initializeTabManager(userId: string, tabId: string) {
    this.userId = userId;
    this.tabId = tabId;
    this.addTab();
    this.listenForTabChanges();
  }

  private addTab() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);

    // First, check if the tab already exists
    onValue(
      tabsRef,
      snapshot => {
        const tabs = snapshot.val();
        let tabExists = false;

        if (tabs) {
          Object.values(tabs).forEach((tab: any) => {
            if (tab.id === this.tabId) {
              tabExists = true;
            }
          });
        }

        // Only add the tab if it doesn't already exist
        if (!tabExists) {
          this.resetActiveTab();
          push(tabsRef, { id: this.tabId, timestamp: Date.now() });
        }
      },
      { onlyOnce: true }
    );
  }

  private listenForTabChanges() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
    onValue(tabsRef, snapshot => {
      const tabs = snapshot.val();
      this.tabCount = tabs ? Object.keys(tabs).length : 0;
      if (this.tabCount > 1) {
        this.showConfirmModal();
      } else if (this.tabCount === 1) {
        this.checkAndUpdateActiveTab();
      }
    });
  }

  private checkAndUpdateActiveTab() {
    const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
    onValue(
      activeTabRef,
      snapshot => {
        const activeTab = snapshot.val();
        if (!activeTab || activeTab !== this.tabId) {
          this.setActiveTab();
        }
      },
      { onlyOnce: true }
    );
  }

  setActiveTab() {
    const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
    set(activeTabRef, this.tabId);
    this.showConfirmSubject.next(false);
    if (this.tabCount > 1) {
      this.redirectOtherTabs();
    }
  }

  resetActiveTab() {
    const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
    set(activeTabRef, '');
  }

  private redirectOtherTabs() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
    onValue(
      tabsRef,
      snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (value.id !== this.tabId) {
              remove(ref(this.db, `usersStatus/${this.userId}/tabs/${key}`));
              this.shouldRedirectSubject.next(false);
            }
          });
        }
      },
      { onlyOnce: true }
    );
  }

  getShowConfirm(): Observable<boolean> {
    return this.showConfirmSubject.asObservable();
  }

  getShouldRedirect(): Observable<boolean> {
    return this.shouldRedirectSubject.asObservable();
  }

  closeTab() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
    onValue(
      tabsRef,
      snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (value.id === this.tabId) {
              remove(ref(this.db, `usersStatus/${this.userId}/tabs/${key}`));
            }
          });
        }
      },
      { onlyOnce: true }
    );
  }

  private showConfirmModal() {
    this.zone.run(() => {
      this.showConfirmSubject.next(true);
    });
  }

  checkIfShouldRedirect(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
      onValue(activeTabRef, snapshot => {
        const activeTab = snapshot.val();

        this.zone.run(() => {
          observer.next(
            this.tabCount > 1 &&
              activeTab &&
              activeTab !== '' &&
              activeTab !== this.tabId
          );
        });
      });
    });
  }
}
