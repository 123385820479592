import { Injectable } from '@angular/core';
import { BaseApi } from './api';

interface ContactFormDTO {
  content: string;
  displayName: string;
  email: string;
  request: string;
  subject: string;
  isVendor: boolean;
}

@Injectable()
export class ContactApi extends BaseApi {
  send(data: ContactFormDTO) {
    return this.post(`/api/contact`, data);
  }
}
