import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { SolutionsToEvaluateStore } from './solutions-to-evaluate.store';
import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from '@angular/fire/auth';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-solutions-to-evaluate',
  templateUrl: './solutions-to-evaluate.component.html',
  styleUrls: ['./solutions-to-evaluate.component.scss'],
  providers: [SolutionsToEvaluateStore],
})
export class SolutionsToEvaluateComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;

  vm$ = this.solutionsToEvaluateStore.vm$;

  public _auth = inject(Auth);

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public solutionsToEvaluateStore: SolutionsToEvaluateStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    // console.log(this._auth.currentUser.uid);
    // console.log(this.authService.afAuth.currentUser);
    this.authService.userData.subscribe(user => {
      if (user) {
        this.solutionsToEvaluateStore.setUid(user?.uid);
        this.solutionsToEvaluateStore.setSolutionType(this.solutionType);
        this.solutionsToEvaluateStore.getBusinessUnitsOfEvaluator();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.solutionsToEvaluateStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.solutionsToEvaluateStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.solutionsToEvaluateStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.solutionsToEvaluateStore.changeSearch(event);
  }

  onRefreshAfterEmit() {
    this.solutionsToEvaluateStore.refreshSolutions();
  }
}
