import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from '../../../../../core/services/auth.service';
import { AllEvaluatedSolutionsStore } from './all-evaluated-solutions.component.store';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { AllSolutionsStore } from '../all-solutions/all-solutions.component.store';
import { NzTableSortOrder } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-admin-all-evaluated-solutions',
  templateUrl: './all-evaluated-solutions.component.html',
  styleUrls: ['./all-evaluated-solutions.component.scss'],
  providers: [AllEvaluatedSolutionsStore],
})
export class AllEvaluatedSolutionsComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;

  vm$ = this.allEvaluatedSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    private allEvaluatedSolutionsStore: AllEvaluatedSolutionsStore,
    private allSolutionsStore: AllSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.allEvaluatedSolutionsStore.setUid(user?.uid);
        this.allEvaluatedSolutionsStore.setSolutionType(this.solutionType);
        this.allEvaluatedSolutionsStore.getCount();
        this.allEvaluatedSolutionsStore.getSolutions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.allEvaluatedSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.allEvaluatedSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.allEvaluatedSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.allEvaluatedSolutionsStore.changeSearch(event);
  }

  onRefreshAfterEmit() {
    this.allEvaluatedSolutionsStore.refreshSolutions();
    this.allSolutionsStore.refreshSolutions();
  }

  sorted(params: { key: string; value: NzTableSortOrder }) {
    this.allEvaluatedSolutionsStore.setSort(params);
    this.allEvaluatedSolutionsStore.getSolutions();
  }
}
