<span *ngIf="presentUsers">
  <span *ngIf="presentUsers.length" class="document-presence">
    <veolia-avatar-group
      *ngIf="presentUsers.length <= 3; else otherPresentUsers">
      <veolia-avatar
        *ngFor="let user of presentUsers"
        nz-tooltip
        [nzTooltipTitle]="user.userFullName"
        [text]="user.initials"
        [size]="'default'"></veolia-avatar>
    </veolia-avatar-group>
    <ng-template #otherPresentUsers>
      <nz-avatar-group>
        <veolia-avatar
          *ngFor="let user of twoFirstPresent"
          nz-tooltip
          [nzTooltipTitle]="user.userFullName"
          [text]="user.initials"
          [size]="'default'"></veolia-avatar>
        <veolia-avatar
          nz-dropdown
          [nzDropdownMenu]="menu"
          [text]="'+' + presentUsers.length.toString()"
          [size]="'default'"></veolia-avatar>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item *ngFor="let user of remainingPresent">
              {{ user.userFullName }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-avatar-group>
    </ng-template>
  </span>
</span>
