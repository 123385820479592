import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Solution } from 'src/app/core/models/solution';
import { SolutionService } from 'src/app/core/services/solution.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { UserService } from 'src/app/core/services/user.service';
import SolutionApi from 'src/app/core/apis/solution.api';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import UserApi from 'src/app/core/apis/user.api';
import { vendorDTO } from 'src/app/shared/features/solution-share/components/solution-share.type';
import { AllPendingSolutionsStore } from '../all-pending-solutions/all-pending-solutions.component.store';
import { AllSolutionsStore } from '../all-solutions/all-solutions.component.store';
import { AllEvaluatedSolutionsStore } from '../all-evaluated-solutions/all-evaluated-solutions.component.store';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { Timestamp } from '@angular/fire/firestore';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-validate-solution',
  templateUrl: './validate-solution.component.html',
  styleUrls: ['./validate-solution.component.scss'],
  // providers: [AllPendingSolutionsStore],
})
export class ValidateSolutionComponent
  extends DataTableComponent
  implements AfterContentChecked
{
  solution?: Solution;
  vendorData?: vendorDTO;

  @ViewChild('validateSolutionModal') modal!: VeoliaModalComponent;

  //@Output() refresh: EventEmitter<void> = new EventEmitter();

  constructor(
    private cDRef: ChangeDetectorRef,
    private aFA: Auth,
    private userService: UserService,
    private solutionApi: SolutionApi,
    private userApi: UserApi,
    private allPendingSolutionsStore: AllPendingSolutionsStore,
    private sscApi: SecurityScoreCardApi,
    private sscService: SecurityScoreCardService,
    private veoliaMessageService: VeoliaMessageService,
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    private allEvaluatedSolutionsStore: AllEvaluatedSolutionsStore,
    private allSolutionsStore: AllSolutionsStore,
    public override solutionService: SolutionService,
    public messageService: VeoliaMessageService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngAfterContentChecked(): void {
    this.cDRef.detectChanges();
  }

  abortRequest() {
    this.solutionService
      .cancelSolution(this.solution?.uid!, this.solution as Solution)
      .then(res => {
        //Ici envoie de mail au SO + DLA + evaluatorsBU que la demande a été cancel solution_cancelled_SaaS
        this.solutionApi
          .notifySolutionCanceled(this.solution?.uid!)
          .pipe(
            tapResponse(
              res => {
                console.log('response from backend routes.py', res);
              },
              (err: HttpErrorResponse) => {
                console.log('error', err);
              }
            )
          )
          .subscribe();
        this.onRefreshAfterEmit();
      });
    this.closeModal();
    this.router.navigate(['/admin/solutions']);
    this.veoliaMessageService.create(
      {
        title: 'Solution canceled !',
        icon: 'check',
        content:
          'Request ' +
          this.solution?.solutionName +
          ' has been canceled successfuly',
      },
      {
        duration: 10000,
      }
    );
  }

  approveRequest() {
    const currentUser = this.aFA.currentUser;
    const currentUserEmail = currentUser?.email;

    this.solutionService
      .approveSolution(this.solution?.uid!, this.solution as Solution)
      .then(() => {
        //Check if vendor user exists or not and create his account and send credentials or not
        return this.userService
          .checkUserExists(this.solution?.mainEmailVendor!)
          .then(snapShot => {
            const isExisting = !snapShot.empty;
            const {
              mainCompanyVendor,
              mainFirstnameVendor,
              mainLastnameVendor,
              mainEmailVendor,
            } = this.solution as Solution;

            this.vendorData = {
              companyVendor: mainCompanyVendor,
              emailVendor: mainEmailVendor,
              firstnameVendor: mainFirstnameVendor,
              lastnameVendor: mainLastnameVendor,
            };

            this.userApi
              .addVendorToSolutionIfExistsOrNotByVendorOrVeolia(
                this.solution!.uid,
                { ...this.vendorData, isExisting },
                currentUserEmail!,
                this.userType!
              )
              .pipe(
                tapResponse(
                  res => {
                    console.log('response from backend routes.py', res);
                  },
                  (err: HttpErrorResponse) => {
                    console.log('error', err);
                    this.veoliaMessageService.create(
                      {
                        title: 'Error',
                        icon: 'error',
                        content: err.message,
                      },
                      {
                        duration: 10000,
                      }
                    );
                  }
                )
              )
              .subscribe();
          });
      })
      .finally(() => {
        // mail de la validation de la demande à DLA + SO + evaluators BU
        // ajout du SSC
        this.sscApi
          .addPortofolio(this.solution?.uid, this.solution?.link)
          .subscribe({
            next: () => {
              this.sscApi
                .getScore(this.solution?.uid, this.solution?.link)
                .subscribe({
                  next: data => {
                    this.sscService
                      .get(this.solution!.uid)
                      .then(doc => {
                        if (doc.exists()) {
                          this.sscService
                            .update(this.solution!.uid, {
                              ...data,
                              lastUpdateDate: Timestamp.now(),
                            })
                            .then(() => {
                              this.messageService.create(
                                {
                                  title: 'Success',
                                  content: 'Security score card refreshed !',
                                  icon: 'check',
                                },
                                {
                                  duration: 3000,
                                }
                              );
                              this.sscApi
                                .removePortofolio(
                                  this.solution?.uid,
                                  this.solution?.link
                                )
                                .subscribe();
                            })
                            .catch(err => console.log(err));
                        } else {
                          this.sscService
                            .create(
                              {
                                ...data,
                                solutionName: this.solution?.solutionName,
                                idSolution: this.solution?.uid,
                                firstAddDate: Timestamp.now(),
                              },
                              this.solution?.uid
                            )
                            .then(() => {
                              this.messageService.create(
                                {
                                  title: 'Success',
                                  content: 'Security score card created !',
                                  icon: 'check',
                                },
                                {
                                  duration: 3000,
                                }
                              );
                              this.sscApi
                                .removePortofolio(
                                  this.solution?.uid,
                                  this.solution?.link
                                )
                                .subscribe();
                            })
                            .catch(err => console.log(err));
                        }
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  },
                  error: () => {
                    this.messageService.create(
                      {
                        title: 'Error',
                        content: 'Oops! Can not refresh security score card',
                        icon: 'error',
                      },
                      {
                        duration: 3000,
                      }
                    );
                  },
                });
            },
            error: () => {
              this.messageService.create(
                {
                  title: 'Error',
                  content:
                    'Oops! Can not add to portofolio security score card',
                  icon: 'error',
                },
                {
                  duration: 3000,
                }
              );
            },
          });
        this.solutionApi
          .notifySolutionValidated(this.solution?.uid!)
          .pipe(
            tapResponse(
              res => {
                console.log('response from backend routes.py', res);
              },
              (err: HttpErrorResponse) => {
                console.log('error', err);
                this.veoliaMessageService.create(
                  {
                    title: 'Error',
                    icon: 'error',
                    content: err.message,
                  },
                  {
                    duration: 10000,
                  }
                );
              }
            )
          )
          .subscribe();
        this.onRefreshAfterEmit();
      });

    this.closeModal();

    this.veoliaMessageService.create(
      {
        title: 'Solution approved !',
        icon: 'check',
        content:
          'Request ' +
          this.solution?.solutionName +
          ' has been approved successfuly',
      },
      {
        duration: 10000,
      }
    );

    this.veoliaMessageService.create(
      {
        title: 'Vendor created and notified',
        icon: 'check',
        content:
          'Vendor user ' +
          this.solution?.mainEmailVendor +
          ' has been created and notified',
      },
      {
        duration: 10000,
      }
    );
  }

  closeModal(): void {
    this.modal.close();
  }

  open(solution: Solution) {
    this.solution = solution;
    this.modal.open();
  }

  onRefreshAfterEmit() {
    this.allPendingSolutionsStore.refreshSolutions();
    //this.allEvaluatedSolutionsStore.refreshSolutions();
    this.allSolutionsStore.refreshSolutions();
  }

  get userType() {
    return this.authService.type;
  }
}
