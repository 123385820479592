import { Component, Input, ViewChild, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Auth } from '@angular/fire/auth';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { FormDividerComponent } from '../form-divider/form-divider.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { DesignFormStore } from 'src/app/features/demands/components/design-form/design-form.store';
import { Reservation } from 'src/app/core/models/reservation';
import { Evaluation } from 'src/app/core/models/evalutation';
import { LogService } from 'src/app/core/services/log.service';
import { EvaluationApi } from 'src/app/core/apis/evaluation.api';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { LegalFormStore } from 'src/app/features/demands/components/legal-form/legal-form.store';
import { Article28FormStore } from 'src/app/features/demands/components/article-28-form/article-28-form.store';
import { Article32FormStore } from 'src/app/features/demands/components/article-32-form/article-32-form.store';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { getTagColor } from 'src/app/core/utils/colors';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Solution } from 'src/app/core/models/solution';

interface IModalData {
  fieldId: string;
  formName: string;
  solutionId: string;
  commentField: string;
  solutionName: string;
  statusField: string;
  status: string;
  statusKey: string;
  comment: string;
  isVendor: boolean;
}

@Component({
  selector: 'app-form-action-status',
  templateUrl: './form-action-status.component.html',
  styleUrls: ['./form-action-status.component.scss'],
})
export class FormActionStatusComponent {
  @Input() evaluation?: Evaluation;
  @Input() isChapter = false;
  @Input() businessUnit?: BusinessUnit;
  @Input() solution?: Solution;
  @ViewChild('modal') modal!: VeoliaModalComponent;

  modalData?: IModalData;
  isSubmitting = false;
  formName?: string;
  solutionId?: string;
  fieldId = '';
  commentField = '';
  solutionName?: string;
  statusField = '';
  reservation?: Reservation;
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  isVendor? = false;
  formNameRoute?: string;

  vm$?: Observable<any>;

  public _auth: Auth = inject(Auth);

  constructor(
    private authService: AuthService,
    private article28FormService: Article28FormService,
    private article28FormStore: Article28FormStore,
    private article32FormService: Article32FormService,
    private article32FormStore: Article32FormStore,
    private designFormService: DesignFormService,
    private designFormStore: DesignFormStore,
    private evaluationApi: EvaluationApi,
    private evaluationService: EvaluationService,
    private legalFormService: LegalFormService,
    private legalFormStore: LegalFormStore,
    private logService: LogService,
    private reservationService: ReservationService,
    private solutionService: SolutionService,
    private translateService: TranslateService,
    private messageService: VeoliaMessageService,
    private auth: Auth
  ) {}

  init(): void {
    const {
      commentField,
      fieldId,
      formName,
      solutionId,
      solutionName,
      statusField,
      isVendor,
    } = this.modalData!;
    this.fieldId = fieldId;
    this.formName = formName;
    this.solutionId = solutionId;
    this.commentField = commentField;
    this.statusField = statusField;
    this.solutionName = solutionName;
    this.isVendor = isVendor;

    this.options.formState.disabled = !this.hasFullRights || this.isVendor;

    if (this.formName === 'Design Form') {
      this.vm$ = this.designFormStore.vm$;
      this.model['id'] = this.designFormStore.getFieldId();
      this.model['status'] =
        this.designFormStore.getFieldEvalution(statusField);
      this.model['comment'] =
        this.designFormStore.getFieldComment(commentField);
      const currentReservation = this.designFormStore.getFieldReservation(
        this.removeRadioSuffix(this.statusField)
      );
      if (currentReservation) {
        this.model['deadlineDate'] = currentReservation.deadlineDate;
      }
    }

    if (this.formName === 'Legal Form') {
      this.vm$ = this.legalFormStore.vm$;
      this.model['id'] = this.legalFormStore.getFieldId();
      this.model['status'] = this.legalFormStore.getFieldEvalution(statusField);
      this.model['comment'] = this.legalFormStore.getFieldComment(commentField);
      const currentReservation = this.designFormStore.getFieldReservation(
        this.removeRadioSuffix(this.statusField)
      );
      if (currentReservation) {
        this.model['deadlineDate'] = currentReservation.deadlineDate;
      }
    }

    if (this.formName === 'Article 28 Form') {
      this.vm$ = this.article28FormStore.vm$;
      this.model['id'] = this.article28FormStore.getFieldId();
      this.model['status'] =
        this.article28FormStore.getFieldEvalution(statusField);
      this.model['comment'] =
        this.article28FormStore.getFieldComment(commentField);
      const currentReservation = this.designFormStore.getFieldReservation(
        this.removeRadioSuffix(this.statusField)
      );
      if (currentReservation) {
        this.model['deadlineDate'] = currentReservation.deadlineDate;
      }
    }

    if (this.formName === 'Article 32 Form') {
      this.vm$ = this.article32FormStore.vm$;
      this.model['id'] = this.article32FormStore.getFieldId();
      this.model['status'] =
        this.article32FormStore.getFieldEvalution(statusField);
      this.model['comment'] =
        this.article32FormStore.getFieldComment(commentField);
      const currentReservation = this.designFormStore.getFieldReservation(
        this.removeRadioSuffix(this.statusField)
      );
      if (currentReservation) {
        this.model['deadlineDate'] = currentReservation.deadlineDate;
      }
    }

    this.fields = [
      {
        key: 'status',
        type: 'nz-status',
        wrappers: [FormDividerComponent],
        props: {
          required: true,
          label: this.translateService.instant(this.fieldId),
          options: [
            { value: 'Recommended', label: 'Recommended' },
            {
              value: 'Recommended with reservations',
              label: 'Recommended with reservations',
            },
            { value: 'Not recommended', label: 'Not recommended' },
            { value: 'On hold', label: 'On hold' },
            { value: 'Not applicable', label: 'Not applicable' },
          ],
        },
        expressions: {
          'props.label': this.translateService.stream(this.fieldId),
          'props.disabled': 'formState.disabled',
        },
      },
      {
        key: 'comment',
        type: 'nz-textarea',
        wrappers: ['form-divider'],
        props: {
          label: 'Add new comment',
        },
        expressions: {
          'props.disabled': 'formState.disabled',
          'props.required':
            'model.status === "On hold" || model.status === "Recommended with reservations" || model.status === "Not recommended"',
        },
      },
      {
        key: 'deadlineDate',
        type: 'nz-datepicker',
        props: {
          label: 'Deadline expected',
        },
        expressions: {
          hide: "model.status !== 'Recommended with reservations'",
          'props.disabled': 'formState.disabled',
        },
      },
    ];
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }

  get isAdmin() {
    return this.authService.roles?.admin === true;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.businessUnit?.evaluators.includes(this.auth.currentUser!.email!) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  resetForm() {
    if (this.options.resetModel) {
      this.options?.resetModel();
    }
    this.model = {
      ...this.model,
      status: null,
      comment: null,
    };
  }

  getIdRefReservation() {
    return `${this.solutionId}-` + this.removeRadioSuffix(this.statusField);
  }

  getIdRefEval() {
    return `${this.solutionId}-${this.formName}`;
  }

  getEvaluationPath() {
    return `evaluations/${this.formName}/${this.solutionId}`;
  }

  getReservationPath() {
    return (
      `reservations/${this.formName}/${this.solutionId}/` +
      this.removeRadioSuffix(this.statusField)
    );
  }

  createEvaluation(data: any) {
    return this.evaluationService.addEvaluation(this.getEvaluationPath(), data);
  }

  updateEvaluation(ref: string, data: any) {
    return this.evaluationService.updateEvaluation(
      `${this.getEvaluationPath()}/${ref}`,
      data
    );
  }

  createReservation(data: any) {
    return this.reservationService.addReservation(
      this.getReservationPath(),
      data
    );
  }

  updateReservation(ref: string, data: any) {
    return this.reservationService.updateReservation(
      `${this.getReservationPath()}/${ref}`,
      data
    );
  }

  initSharedData(): {
    postedAt: string;
    postedBy?: string;
    idSolution?: string;
    userId?: string;
  } {
    return {
      postedAt: new Date(Date.now()).toString(),
      postedBy: this._auth.currentUser?.email || '',
      idSolution: this.solutionId,
      userId: this._auth.currentUser?.uid,
    };
  }

  submitNewEvaluation() {
    //Add new evaluation
    const sharedData = this.initSharedData();
    const evaluation: any = {
      idRefEval: this.getIdRefEval(),
      status: 'DLA is reviewing',
      solutionName: this.solution?.solutionName,
      ...sharedData,
    };
    evaluation[this.commentField] = this.model.comment;
    evaluation[this.statusField] = this.model.status;

    this.createEvaluation(evaluation)
      .then(() => {
        this.logService.setLogForAction(
          'Evaluation',
          this.solutionId!,
          this.formName!,
          {
            action: 'Evaluation saved',
            chapter: this.statusField,
            comment: this.model.comment,
            roleUser: this.authService.userRole,
            status: this.model.status,
          }
        );

        this.messageService.create(
          {
            title: 'Success',
            icon: 'check_box',
            content: 'Evaluation created',
          },
          {
            duration: 5000,
          }
        );

        if (
          this.model.status === 'Recommended with reservations' ||
          this.model.status === 'Reservations'
        ) {
          this.createReservation({
            content: this.model.comment,
            deadlineDate: this.model.deadlineDate,
            fixed: false,
            formIs: this.formName,
            solutionName: this.solution?.solutionName,
            idRefReservation: this.getIdRefReservation(),
            reference: this.removeRadioSuffix(this.statusField),
            ...sharedData,
            userRole: this.authService.userRole,
          })
            .then(() => {
              this.messageService.create(
                {
                  title: 'Success',
                  icon: 'check_box',
                  content: 'Reservation created',
                },
                {
                  duration: 5000,
                }
              );
            })
            .catch(error => {
              this.messageService.create(
                {
                  title: 'Error',
                  icon: 'error',
                  content: error || 'Oops! Erorr occured',
                },
                {
                  duration: 5000,
                }
              );
            });
        }

        this.modal.close();
      })
      .catch(error => {
        this.messageService.create(
          {
            title: 'Error',
            icon: 'error',
            content: error || 'Oops! Erorr occured',
          },
          {
            duration: 5000,
          }
        );
      });

    if (this.formName === 'Design Form') {
      this.designFormStore.getEvaluation();
      this.designFormStore.getAllReservations();
      this.formNameRoute = 'designForm';
    }

    if (this.formName === 'Legal Form') {
      this.legalFormStore.getEvaluation();
      this.legalFormStore.getAllReservations();
      this.formNameRoute = 'legalForm';
    }

    if (this.formName === 'Article 28 Form') {
      this.article28FormStore.getEvaluation();
      this.article28FormStore.getAllReservations();
      this.formNameRoute = 'article28Form';
    }

    if (this.formName === 'Article 32 Form') {
      this.article32FormStore.getEvaluation();
      this.article32FormStore.getAllReservations();
      this.formNameRoute = 'article32Form';
    }

    const entries = Object.entries(evaluation);
    let onHold = false;

    if (entries.some(([key, value]) => value === 'On hold')) {
      onHold = true;
    }

    if (onHold) {
      //envoi de la notif que si c'est un chapitre ex:DA_X_RADIO mais pas sous chapitre ex:DA_XX_RADIO
      if (this.isChapterRadio(this.statusField)) {
        this.evaluationApi
          .notifyOnHold(this.solutionId!, {
            formName: this.formName!,
            chapter: this.statusField,
            comment: this.model.comment,
            status: this.model.status,
            displayName: this._auth.currentUser?.displayName!,
            postedAt: new Date().toDateString(),
            formNameRoute: this.formNameRoute!,
          })
          .subscribe();
      }
      //if On hold on passe le status à DLA is reviewing
      let solutionData = {};
      if (this.formName === 'Design Form') {
        solutionData = {
          designStatus: 'DLA is reviewing',
        };
        this.designFormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Legal Form') {
        solutionData = {
          legalStatus: 'DLA is reviewing',
        };
        this.legalFormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Article 28 Form') {
        solutionData = {
          article28Status: 'DLA is reviewing',
        };
        this.article28FormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Article 32 Form') {
        solutionData = {
          article32Status: 'DLA is reviewing',
        };
        this.article32FormService.update(this.solutionId!, solutionData, false);
      }

      this.solutionService.update(
        this.solutionId!,
        {
          status: 'Ongoing',
          ...solutionData,
        },
        false
      );

      this.updateEvaluation(evaluation.id!, {
        status: 'DLA is reviewing',
      });
    } else {
      let solutionData = {};
      if (this.formName === 'Design Form') {
        solutionData = {
          designStatus: 'DLA is reviewing',
        };
        this.designFormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Legal Form') {
        solutionData = {
          legalStatus: 'DLA is reviewing',
        };
        this.legalFormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Article 28 Form') {
        solutionData = {
          article28Status: 'DLA is reviewing',
        };
        this.article28FormService.update(this.solutionId!, solutionData, false);
      }
      if (this.formName === 'Article 32 Form') {
        solutionData = {
          article32Status: 'DLA is reviewing',
        };
        this.article32FormService.update(this.solutionId!, solutionData, false);
      }

      this.solutionService.update(
        this.solutionId!,
        {
          status: 'Ongoing',
          ...solutionData,
        },
        false
      );

      this.updateEvaluation(evaluation.id!, {
        status: 'DLA is reviewing',
      });
    }
  }

  submitEditEvaluation() {
    //Edit existing evaluation
    const sharedData = this.initSharedData();
    const evaluation: Evaluation = {
      ...this.getFormEvaluation(),
      status: this.model.status,
      ...sharedData,
    };
    evaluation[this.commentField] = this.model.comment;
    evaluation[this.statusField] = this.model.status;

    this.updateEvaluation(evaluation.id!, evaluation)
      .then(() => {
        this.logService.setLogForAction(
          'Evaluation',
          this.solutionId!,
          this.formName!,
          {
            action: 'Evaluation edited',
            chapter: this.statusField,
            comment: this.model.comment,
            roleUser: this.authService.userRole,
            status: this.model.status,
          }
        );
        const currentReservation = this.designFormStore.getFieldReservation(
          this.removeRadioSuffix(this.statusField)
        );

        this.messageService.create(
          {
            title: 'Success',
            icon: 'check_box',
            content: 'Evaluation edited successfully',
          },
          {
            duration: 5000,
          }
        );

        if (this.formName === 'Design Form') {
          this.designFormStore.getEvaluation();
        }

        if (this.formName === 'Legal Form') {
          this.legalFormStore.getEvaluation();
        }

        if (this.formName === 'Article 28 Form') {
          this.article28FormStore.getEvaluation();
        }

        if (this.formName === 'Article 32 Form') {
          this.article32FormStore.getEvaluation();
        }

        this.modal.close();

        if (
          this.model.status === 'Recommended with reservations' ||
          this.model.status === 'Reservations'
        ) {
          if (currentReservation) {
            this.updateReservation(currentReservation.idRef, {
              ...currentReservation,
              content: this.model.comment,
              deadlineDate: this.model.deadlineDate,
              ...sharedData,
            })
              .then(() => {
                this.messageService.create(
                  {
                    title: 'Success',
                    icon: 'check_box',
                    content: 'Reservation edited successfully',
                  },
                  {
                    duration: 5000,
                  }
                );
                if (this.formName === 'Design Form') {
                  this.designFormStore.getAllReservations();
                  this.formNameRoute = 'designForm';
                }

                if (this.formName === 'Legal Form') {
                  this.legalFormStore.getAllReservations();
                  this.formNameRoute = 'legalForm';
                }

                if (this.formName === 'Article 28 Form') {
                  this.article28FormStore.getAllReservations();
                  this.formNameRoute = 'article28Form';
                }

                if (this.formName === 'Article 32 Form') {
                  this.article32FormStore.getAllReservations();
                  this.formNameRoute = 'article32Form';
                }
                this.modal.close();
              })
              .catch(error => {
                this.messageService.create(
                  {
                    title: 'Error',
                    icon: 'check_box',
                    type: 'error',
                    content: error,
                  },
                  {
                    duration: 5000,
                  }
                );
                this.modal.close();
              });
          } else {
            this.createReservation({
              content: this.model.comment,
              deadlineDate: this.model.deadlineDate,
              fixed: false,
              formIs: this.formName,
              solutionName: this.solution?.solutionName,
              idRefReservation: this.getIdRefReservation(),
              reference: this.removeRadioSuffix(this.statusField),
              ...sharedData,
              userRole: this.authService.userRole,
            })
              .then(() => {
                this.messageService.create(
                  {
                    title: 'Success',
                    icon: 'check_box',
                    content: 'Reservation created successfully',
                  },
                  {
                    duration: 5000,
                  }
                );
                if (this.formName === 'Design Form') {
                  this.designFormStore.getAllReservations();
                  this.formNameRoute = 'designForm';
                }

                if (this.formName === 'Legal Form') {
                  this.legalFormStore.getAllReservations();
                  this.formNameRoute = 'legalForm';
                }

                if (this.formName === 'Article 28 Form') {
                  this.article28FormStore.getAllReservations();
                  this.formNameRoute = 'article28Form';
                }

                if (this.formName === 'Article 32 Form') {
                  this.article32FormStore.getAllReservations();
                  this.formNameRoute = 'article32Form';
                }

                this.modal.close();
              })
              .catch(error => {
                this.messageService.create(
                  {
                    title: 'Error',
                    icon: 'check_box',
                    type: 'error',
                    content: error,
                  },
                  {
                    duration: 5000,
                  }
                );
              });
            this.modal.close();
          }
        }

        const entries = Object.entries(evaluation);
        let onHold = false;

        if (entries.some(([key, value]) => value === 'On hold')) {
          onHold = true;
        }

        if (onHold) {
          if (this.formName === 'Design Form') {
            this.formNameRoute = 'designForm';
          }

          if (this.formName === 'Legal Form') {
            this.formNameRoute = 'legalForm';
          }

          if (this.formName === 'Article 28 Form') {
            this.formNameRoute = 'article28Form';
          }

          if (this.formName === 'Article 32 Form') {
            this.formNameRoute = 'article32Form';
          }

          //envoi de la notif que si c'est un chapitre ex:DA_X_RADIO mais pas sous chapitre ex:DA_XX_RADIO
          if (this.isChapterRadio(this.statusField)) {
            this.evaluationApi
              .notifyOnHold(this.solutionId!, {
                formName: this.formName!,
                chapter: this.statusField,
                comment: this.model.comment,
                status: this.model.status,
                displayName: this._auth.currentUser?.displayName!,
                postedAt: new Date().toDateString(),
                formNameRoute: this.formNameRoute!,
              })
              .subscribe();
          }

          //if On hold on passe le status à DLA is reviewing
          let solutionData = {};
          //DESIGN
          if (
            this.formName === 'Design Form' &&
            this.solution?.designStatus !== 'Recommended' &&
            this.solution?.designStatus !== 'Reservations' &&
            this.solution?.designStatus !== 'Not recommended'
          ) {
            solutionData = {
              designStatus: 'DLA is reviewing',
            };
            this.designFormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Design Form' &&
            (this.solution?.designStatus === 'Recommended' ||
              this.solution?.designStatus === 'Reservations' ||
              this.solution?.designStatus === 'Not recommended')
          ) {
            solutionData = {
              designStatus: this.solution?.designStatus,
            };
            this.designFormService.update(this.solutionId!, solutionData, false);
          }
          //LEGAL
          if (
            this.formName === 'Legal Form' &&
            this.solution?.legalStatus !== 'Recommended' &&
            this.solution?.legalStatus !== 'Reservations' &&
            this.solution?.legalStatus !== 'Not recommended'
          ) {
            solutionData = {
              legalStatus: 'DLA is reviewing',
            };
            this.legalFormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Legal Form' &&
            (this.solution?.legalStatus === 'Recommended' ||
              this.solution?.legalStatus === 'Reservations' ||
              this.solution?.legalStatus === 'Not recommended')
          ) {
            solutionData = {
              legalStatus: this.solution?.legalStatus,
            };
            this.legalFormService.update(this.solutionId!, solutionData, false);
          }
          //ARTICLE 32
          if (
            this.formName === 'Article 32 Form' &&
            this.solution?.article32Status !== 'Recommended' &&
            this.solution?.article32Status !== 'Reservations' &&
            this.solution?.article32Status !== 'Not recommended'
          ) {
            solutionData = {
              article32Status: 'DLA is reviewing',
            };
            this.article32FormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Article 32 Form' &&
            (this.solution?.article32Status === 'Recommended' ||
              this.solution?.article32Status === 'Reservations' ||
              this.solution?.article32Status === 'Not recommended')
          ) {
            solutionData = {
              article32Status: this.solution?.article32Status,
            };
            this.article32FormService.update(this.solutionId!, solutionData, false);
          }
          //ARTICLE 28
          if (
            this.formName === 'Article 28 Form' &&
            this.solution?.article28Status !== 'Recommended' &&
            this.solution?.article28Status !== 'Reservations' &&
            this.solution?.article28Status !== 'Not recommended'
          ) {
            solutionData = {
              article28Status: 'DLA is reviewing',
            };
            this.article28FormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Article 28 Form' &&
            (this.solution?.article28Status === 'Recommended' ||
              this.solution?.article28Status === 'Reservations' ||
              this.solution?.article28Status === 'Not recommended')
          ) {
            solutionData = {
              article28Status: this.solution?.article28Status,
            };
            this.article28FormService.update(this.solutionId!, solutionData, false);
          }
          //tant qu'il y'a un On hold
          this.solutionService.update(
            this.solutionId!,
            {
              status: 'Ongoing',
              ...solutionData,
            },
            false
          );
          //tant qu'il y'a un On hold
          this.updateEvaluation(evaluation.id!, {
            status: 'DLA is reviewing',
          });
        } else {
          let solutionData = {};
          //DESIGN
          if (
            this.formName === 'Design Form' &&
            this.solution?.designStatus !== 'Recommended' &&
            this.solution?.designStatus !== 'Reservations' &&
            this.solution?.designStatus !== 'Not recommended'
          ) {
            solutionData = {
              designStatus: 'DLA is reviewing',
            };
            this.designFormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Design Form' &&
            (this.solution?.designStatus === 'Recommended' ||
              this.solution?.designStatus === 'Reservations' ||
              this.solution?.designStatus === 'Not recommended')
          ) {
            solutionData = {
              designStatus: this.solution?.designStatus,
            };
            this.designFormService.update(this.solutionId!, solutionData, false);
          }
          //LEGAL
          if (
            this.formName === 'Legal Form' &&
            this.solution?.legalStatus !== 'Recommended' &&
            this.solution?.legalStatus !== 'Reservations' &&
            this.solution?.legalStatus !== 'Not recommended'
          ) {
            solutionData = {
              legalStatus: 'DLA is reviewing',
            };
            this.legalFormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Legal Form' &&
            (this.solution?.legalStatus === 'Recommended' ||
              this.solution?.legalStatus === 'Reservations' ||
              this.solution?.legalStatus === 'Not recommended')
          ) {
            solutionData = {
              legalStatus: this.solution?.legalStatus,
            };
            this.legalFormService.update(this.solutionId!, solutionData, false);
          }
          //ARTICLE 32
          if (
            this.formName === 'Article 32 Form' &&
            this.solution?.article32Status !== 'Recommended' &&
            this.solution?.article32Status !== 'Reservations' &&
            this.solution?.article32Status !== 'Not recommended'
          ) {
            solutionData = {
              article32Status: 'DLA is reviewing',
            };
            this.article32FormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Article 32 Form' &&
            (this.solution?.article32Status === 'Recommended' ||
              this.solution?.article32Status === 'Reservations' ||
              this.solution?.article32Status === 'Not recommended')
          ) {
            solutionData = {
              article32Status: this.solution?.article32Status,
            };
            this.article32FormService.update(this.solutionId!, solutionData, false);
          }
          //ARTICLE 28
          if (
            this.formName === 'Article 28 Form' &&
            this.solution?.article28Status !== 'Recommended' &&
            this.solution?.article28Status !== 'Reservations' &&
            this.solution?.article28Status !== 'Not recommended'
          ) {
            solutionData = {
              article28Status: 'DLA is reviewing',
            };
            this.article28FormService.update(this.solutionId!, solutionData, false);
          }
          if (
            this.formName === 'Article 28 Form' &&
            (this.solution?.article28Status === 'Recommended' ||
              this.solution?.article28Status === 'Reservations' ||
              this.solution?.article28Status === 'Not recommended')
          ) {
            solutionData = {
              article28Status: this.solution?.article28Status,
            };
            this.article28FormService.update(this.solutionId!, solutionData, false);
          }

          //If solution has not been fully evaluated
          if (
            this.solution?.status !== 'Recommended' &&
            this.solution?.status !== 'Reservations' &&
            this.solution?.status !== 'Not recommended'
          ) {
            this.solutionService.update(
              this.solutionId!,
              {
                status: 'Ongoing',
                ...solutionData,
              },
              false
            );

            this.updateEvaluation(evaluation.id!, {
              status: 'DLA is reviewing',
            });
          }
          //If solution has been already fully evaluated
          //DESIGN
          if (
            this.formName === 'Design Form' &&
            (this.solution?.designStatus === 'Recommended' ||
              this.solution?.designStatus === 'Reservations' ||
              this.solution?.designStatus === 'Not recommended')
          ) {
            this.updateEvaluation(evaluation.id!, {
              status: this.solution?.designStatus,
            });
          }
          //LEGAL
          if (
            this.formName === 'Legal Form' &&
            (this.solution?.legalStatus === 'Recommended' ||
              this.solution?.legalStatus === 'Reservations' ||
              this.solution?.legalStatus === 'Not recommended')
          ) {
            this.updateEvaluation(evaluation.id!, {
              status: this.solution?.legalStatus,
            });
          }
          //ARTICLE 32
          if (
            this.formName === 'Article 32 Form' &&
            (this.solution?.article32Status === 'Recommended' ||
              this.solution?.article32Status === 'Reservations' ||
              this.solution?.article32Status === 'Not recommended')
          ) {
            this.updateEvaluation(evaluation.id!, {
              status: this.solution?.article32Status,
            });
          }
          //ARTICLE 28
          if (
            this.formName === 'Article 28 Form' &&
            (this.solution?.article28Status === 'Recommended' ||
              this.solution?.article28Status === 'Reservations' ||
              this.solution?.article28Status === 'Not recommended')
          ) {
            this.updateEvaluation(evaluation.id!, {
              status: this.solution?.article28Status,
            });
          }
        }
      })
      .catch(error => {
        this.designFormStore.setError(error);
      });
  }

  submit() {
    if (this.form.valid) {
      if (this.model.id) {
        this.submitEditEvaluation();
      } else {
        this.submitNewEvaluation();
      }
    }
  }

  open(data: IModalData, isVendor?: boolean) {
    this.modalData = data;
    this.init();
    this.isVendor = isVendor;
    this.modal.open();
  }

  getColor(status: string) {
    return getTagColor(status);
  }

  getFormEvaluation() {
    if (this.formName === 'Design Form') {
      return this.designFormStore.getCurrentEvaluation();
    }

    if (this.formName === 'Legal Form') {
      return this.legalFormStore.getCurrentEvaluation();
    }

    if (this.formName === 'Article 28 Form') {
      return this.article28FormStore.getCurrentEvaluation();
    }

    if (this.formName === 'Article 32 Form') {
      return this.article32FormStore.getCurrentEvaluation();
    }

    return undefined;
  }

  //Pour les réservation le statusField est sans le _RADIO
  removeRadioSuffix(input: string): string {
    return input.replace(/_RADIO$/, '');
  }

  //Pour le onhold savoir si c'est un chapitre ou sous chapitre
  isChapterRadio(input: string): boolean {
    return /^[a-zA-Z0-9]+_\d_RADIO$/.test(input);
  }
}
