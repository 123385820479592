<div class="row row-vertical-center" *ngIf="assessable">
  <veolia-link
    [size]="'default'"
    [variant]="commentsCount ? 'primary' : 'secondary'"
    [icon]="'comment'"
    (click)="openComments($event)">
    {{ commentsCount }} comments
  </veolia-link>
  <veolia-link
    [size]="'default'"
    [variant]="filesCount ? 'primary' : 'secondary'"
    [icon]="'attach_file'"
    (click)="openFiles($event)">
    {{ filesCount }} files
  </veolia-link>
  <ng-container *ngIf="vm$ | async as vm">
    <nz-spin *ngIf="changing" nzSimple></nz-spin>
    <veolia-switch
      *ngIf="completable"
      [disabled]="!canComplete"
      (click)="switchClick($event)"
      (valueChange)="updateChapterStatus($event)"
      [value]="completionStatus"
      [size]="'small'"
      [label]="'Completed'"
      [withContainer]="true">
    </veolia-switch>
  </ng-container>
  <ng-container *ngIf="vm$ | async as vm">
    <veolia-tag
      [label]="evaluationStatus!"
      [align]="'left'"
      (click)="openEvaluations($event)"
      [color]="evaluationColor!" />
  </ng-container>
</div>
<ng-template #formActionCommentsTpl let-data>
  <app-form-action-comment
    (commentsRefreshed)="refreshCommentsCount()"
    [modalData]="data"></app-form-action-comment>
</ng-template>
<ng-template #formActionFilesTpl let-data>
  <app-form-actions-files
    (filesRefreshed)="refreshFilesCount()"
    [modalData]="data"></app-form-actions-files>
</ng-template>
<app-form-action-status
  [evaluation]="formEvaluation"
  [isChapter]="isChapter"
  [businessUnit]="getBusinessUnit()"
  [solution]="getSolution()"
  #formActionStatus></app-form-action-status>
